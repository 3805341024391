import React, { useEffect, useContext, useState, useCallback } from 'react';
import AuthContext from '../../context/auth-context'
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import './style.css'
import mManajemenOutlet from './ManajemenOutletControler'
import DialogInfo from '../../Dialog/DialogInfo'
import ModalFormVoucher from './Modal.Form.Voucher'

const ManajementOutlet = (props) => {
    const kodeoutlet = props.match.params.kodeoutlet;
    const defaultPage = 1;
    const defaultrowsPerPage = 5;
    const { clientID, token, userLogin } = useContext(AuthContext);
    const [isReload, setIsReload] = useState(true)
    const [listOutlet, setListOutlet] = useState({})
    const [isFormVoucher, setIsFormVoucher] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [infoText, setInfoText] = useState("")

    useEffect(() => {
        if (isReload) {
            if (clientID && token && userLogin) {
                mManajemenOutlet.Outlet(userLogin, clientID, token, kodeoutlet).then(resp => {
                    setListOutlet(resp);
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    setIsReload(false);
                })
            }
        }
        return () => {
        };
    }, [clientID, token, userLogin, isReload])


    const sinkronData = useCallback(() => {
        mManajemenOutlet.sinkronData(clientID, token, kodeoutlet)
            .then(() => {
                alert("Data Berhasil Disinkronkan")
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
    }, [clientID, token, kodeoutlet])

    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container>
                    <h3 className="text-center manajemenOutlet">{listOutlet.namaoutlet}</h3>
                    <h6 className='text-center'> <Button onClick={sinkronData}>Sinkron Data</Button></h6>
                    <div style={{ marginTop: '45px' }}>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Row className="borderMenu">
                                    <Col xs={12} className="menuName">
                                        <span className="manajemenOutlet">Data Master</span>
                                    </Col>
                                    <Col xs={6} lg={4}>
                                        <Link to={`/manajemenoutlet/${kodeoutlet}/kategori/${defaultPage}/${defaultrowsPerPage}`}>
                                            <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/kategori-min.png`)} />
                                        </Link>
                                    </Col>
                                    <Col xs={6} lg={4}>
                                        <Link to={`/manajemenoutlet/${kodeoutlet}/subkategori/${defaultPage}/${defaultrowsPerPage}`}>
                                            <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/subkategori-min.png`)} />
                                        </Link>
                                    </Col>
                                    <Col xs={6} lg={4}>
                                        <Link to={`/manajemenoutlet/${kodeoutlet}/barang/${defaultPage}/${defaultrowsPerPage}`}>
                                            <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/barang-min.png`)} />
                                        </Link>
                                    </Col>
                                    <Col xs={6} lg={4}>
                                        <Link to={`/manajemenoutlet/${kodeoutlet}/payment/${defaultPage}/${defaultrowsPerPage}`}>
                                            <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/payment-min.png`)} />
                                        </Link>
                                    </Col>
                                    {(listOutlet.tipeOutlet === "NORMAL") && (
                                        <React.Fragment>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/paket/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/paket-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/list-diskon/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/diskon-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/promo/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/promo-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/waiter/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/waiter-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/zona/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/zona-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/topping`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/topping-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/opname-user/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/user_opname-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/listkasmasuk/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/listkasmasuk.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/listkaskeluar/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/listkaskeluar.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/member/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/member-min.png`)} />
                                                </Link>
                                            </Col>

                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/pengaturan`}>
                                                    <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/pengaturan-min.png`)} />
                                                </Link>
                                            </Col>
                                        </React.Fragment>
                                    )}
                                    {listOutlet.usePoktan && (
                                        <Col xs={6} lg={4}>
                                            <Link to={`/manajemenoutlet/${kodeoutlet}/member-messaging/${defaultPage}/${defaultrowsPerPage}`}>
                                                <img className="img-fluid img-margin" alt="logo" src={require(`../../Icon/poktan-min.png`)} />
                                            </Link>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Row className="borderMenu">
                                    <Col xs={12} className="menuName">
                                        <span className="manajemenOutlet">Payment</span>
                                    </Col>
                                    <Col xs={6} lg={4}>
                                        <Link to={`/manajemenoutlet/${kodeoutlet}/transhistory/${defaultPage}/${defaultrowsPerPage}`}>
                                            <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/riwayatTransaksi-min.png`)} />
                                        </Link>
                                    </Col>
                                    {(listOutlet.tipeOutlet === "NORMAL") && (
                                        <React.Fragment>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/settlementpassti/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/Settlement_PASTI-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/historysettlementpassti/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/History_Settlement_PASTI-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/kasmasuk/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/lapkasmasuk-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/kaskeluar/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/lapkaskeluar-min.png`)} />
                                                </Link>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <img className="img-fluid img-margin tangan" alt="logo" width="auto" src={require(`../../Icon/ClaimVoucher-min.png`)} onClick={() => setIsFormVoucher(true)} />
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Link to={`/manajemenoutlet/${kodeoutlet}/poin/${defaultPage}/${defaultrowsPerPage}`}>
                                                    <img className="img-fluid img-margin" alt="logo" width="auto" src={require(`../../Icon/point-min.png`)} />
                                                </Link>
                                            </Col>
                                        </React.Fragment>
                                    )}

                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {isInfo && (
                        <DialogInfo open={isInfo} setOpen={setIsInfo} text={infoText} />
                    )}
                    {isFormVoucher && (
                        <ModalFormVoucher open={isFormVoucher} setOpen={setIsFormVoucher} clientID={clientID} token={token} setInfoText={setInfoText} setIsInfo={setIsInfo} kodeoutlet={listOutlet.kodeoutlet} />
                    )}
                </Container>
            </main>
        </React.Fragment>
    )
}
export default ManajementOutlet;